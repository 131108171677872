<template>
  <div class="dashboard-container">
    <div>
      <el-tabs v-model="type" @tab-click="handleClick">
        <el-tab-pane label="询价总览表" name="1">
          <inquiry-list ref="tab1" :option-list="optionList" />
        </el-tab-pane>
        <el-tab-pane label="散货询价市级总览表" name="2">
          <bulk-cargo-report ref="tab2" :option-list="optionList" />
        </el-tab-pane>
        <el-tab-pane label="散货询价区级总览表" name="4">
          <district-level-overview ref="tab4" :option-list="optionList" />
        </el-tab-pane>
        <el-tab-pane label="拖车询价总览表" name="3">
          <trailer-report ref="tab3" :option-list="optionList" />
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <div v-if="type == '1'">
      <inquiry-list :optionList="optionList"></inquiry-list>
    </div>
    <div v-if="type == '2'">
      <bulk-cargo-report :optionList="optionList"></bulk-cargo-report>
    </div>
    <div v-if="type == '3'">
        <trailer-report :optionList="optionList"></trailer-report>
    </div> -->
  </div>
</template>
<script>
  import { cityGetValidList } from '@/api/businessReport'
import {
  portGetValidList,
  productCommissionerGetValidList,
} from '@/api/inquiryManagement'
import BulkCargoReport from './components/bulkCargoReport.vue'
import DistrictLevelOverview from './components/districtLevelOverview.vue'
import InquiryList from './components/inquiryList.vue'
import TrailerReport from './components/trailerReport.vue'
  export default {
    name: 'ProductReport',
    components: {
      InquiryList,
      BulkCargoReport,
      TrailerReport,
      DistrictLevelOverview,
    },
    data() {
      return {
        type: '1',
        optionList: {
          productCommissionerGetValidList: [], //业务专员
          cityGetValidList: [], //城市
          portGetValidList: [], //港口
        },

        form: {},
      }
    },
    mounted() {
      this.selectList()
    },
    activated() {
      this.queryList()
    },
    methods: {
      async selectList() {
        const res = await productCommissionerGetValidList({})
        this.optionList.productCommissionerGetValidList = res.data
        const rew = await cityGetValidList({})
        this.optionList.cityGetValidList = rew.data
        const req = await portGetValidList({})
        this.optionList.portGetValidList = req.data
      },
      queryList() {
        this.$nextTick(() => {
          switch (this.type) {
            case '1':
              this.$refs['tab1'].queryList()
              break
            case '2':
              this.$refs['tab2'].queryList()
              break
            case '3':
              this.$refs['tab3'].queryList()
              break
            case '4':
              this.$refs['tab4'].queryList()
              break
          }
        })
      },
      handleClick(tab, event) {
        this.queryList()
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-select__tags-text {
      display: inline-block;
      max-width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .el-select .el-tag__close.el-icon-close {
      top: -7px;
    }
  }
</style>
