<template>
  <div>
    <el-form ref="form" :inline="true" :model="form" @submit.native.prevent>
      <el-form-item>
        时间
        <el-tooltip
          class="item"
          effect="dark"
          content="默认为前30天的数据，不包括今日"
          placement="top"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
        <el-date-picker
          v-model="beginTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          unlink-panels
          @change="pickerClick"
          style="width: 350px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="产品专员">
        <el-select
          v-model="form.productCommissioner"
          multiple
          filterable
          collapse-tags
          @change="handleBlur"
          style="width:180px"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in optionList.productCommissionerGetValidList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div style="height: calc(100vh - 250px)">
    <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      height="100%"
      element-loading-text="拼命加载中"
      @selection-change="setSelectRows"
      header-cell-class-name="table-header"
      class="table eleChange"
    >
      <el-table-column
        align="left"
        prop="type"
        label="类型"
        show-overflow-tooltip
      />
      <el-table-column
        align="left"
        prop="inquiryNum"
        label="询价量"
        show-overflow-tooltip
      />
      <el-table-column
        align="left"
        prop="dealNum"
        label="已成交询价量"
        show-overflow-tooltip
      />
      <el-table-column
        align="left"
        prop="notDealNum"
        label="未成交询价量"
        show-overflow-tooltip
      />
      <el-table-column
        align="left"
        prop="dealPercent"
        label="询价成交率"
        show-overflow-tooltip
      />
      <template #empty>
        <el-image
          class="vab-data-empty"
          :src="require('@/assets/empty_images/data_empty.png')"
        />
      </template>
    </el-table>
    </div>
  </div>
</template>
<script>
import { productGetInquiryOverviewList } from '@/api/businessReport'
import * as localFilter from "@/utils/dataTime"
export default {
  props: {
    optionList: Object,
  },
  data() {
    return {
      listLoading: false,
      list: [],
      form: {
        beginDate: '',
        endDate: '',
        productCommissioner: [],
      },
      beginTime: [],
      pickerOptions: {},
    }
  },
  mounted() {
    this.beginTime.push(localFilter.getDay(-30),localFilter.getDay(-1))
    this.form.beginDate=localFilter.getDay(-30)
    this.form.endDate=localFilter.getDay(-1)
    this.queryList()
  },
  methods: {
    pickerClick() {
      this.form.beginDate = this.beginTime ? this.beginTime[0] : ''
      this.form.endDate = this.beginTime ? this.beginTime[1] : ''
      this.queryList()
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleBlur() {
      this.queryList()
    },
    async queryList() {
      this.listLoading = true
      const res = await productGetInquiryOverviewList(this.form)
      this.list = res.data
      this.listLoading = false
    },
  },
}
</script>