<template>
  <div>
    <el-form ref="form" :inline="true" :model="form" @submit.native.prevent>
      <el-form-item>
        时间
        <el-tooltip
          class="item"
          content="默认为前30天的数据，不包括今日"
          effect="dark"
          placement="top"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
        <el-date-picker
          v-model="beginTime"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          style="width: 350px"
          type="daterange"
          unlink-panels
          value-format="yyyy-MM-dd"
          @change="pickerClick"
        />
      </el-form-item>
      <el-form-item label="产品专员">
        <el-select
          v-model="form.productCommissioner"
          clearable
          collapse-tags
          filterable
          multiple
          placeholder="请选择"
          style="width: 180px"
          @change="handleBlur"
        >
          <el-option
            v-for="item in optionList.productCommissionerGetValidList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="起运市">
        <el-select
          v-model="form.startCity"
          clearable
          collapse-tags
          filterable
          multiple
          placeholder="请选择"
          style="width: 180px"
          @change="handleBlur"
        >
          <el-option
            v-for="item in optionList.cityGetValidList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="目的市">
        <el-select
          v-model="form.destCity"
          clearable
          collapse-tags
          filterable
          multiple
          placeholder="请选择"
          style="width: 180px"
          @change="handleBlur"
        >
          <el-option
            v-for="item in optionList.cityGetValidList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="排序列名">
        <el-select
          v-model="form.sortName"
          clearable
          filterable
          placeholder="请选择"
          style="width: 180px"
          @change="handleBlur"
        >
          <el-option
            v-for="item in optionListOne"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="排序方式">
        <el-select
          v-model="form.sortRule"
          filterable
          placeholder="请选择"
          style="width: 100px"
          @change="handleBlur"
        >
          <el-option
            v-for="item in optionListTwo"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div style="height: calc(100vh - 300px)">
      <el-table
        ref="tableSort"
        v-loading="listLoading"
        border
        class="table eleChange"
        :data="list"
        element-loading-text="拼命加载中"
        header-cell-class-name="table-header"
        height="100%"
        @selection-change="setSelectRows"
      >
        <el-table-column
          align="left"
          label="起运市"
          prop="pickCityName"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          label="目的市"
          prop="dispatchCityName"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          label="询价量"
          prop="inquiryNum"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          label="已成交询价量"
          prop="dealNum"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          label="未成交询价量"
          prop="notDealNum"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          label="询价成交率"
          prop="dealPercent"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          label="总体积"
          prop="volumeSum"
          show-overflow-tooltip
        >
          <template slot="header">
            总体积
            <el-tooltip content="CBM" effect="dark" placement="top">
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          label="总重量"
          prop="weightSum"
          show-overflow-tooltip
        >
          <template slot="header">
            总重量
            <el-tooltip content="KG" effect="dark" placement="top">
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
    </div>
    <el-pagination
      background
      :current-page="form.pageNum"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
  import { productGetBulkCargoInquiryList } from '@/api/businessReport';
import * as localFilter from '@/utils/dataTime';
  export default {
    props: {
      optionList: Object,
    },
    data() {
      return {
        listLoading: false,
        height: this.$baseTableHeight(3) - 30,
        list: [],
        form: {
          sortName: 'inquiryNum',
          sortRule: '2',
          beginDate: '',
          endDate: '',
          productCommissioner: [],
          startCity: [],
          destCity: [],
          pageNum: 1,
          pageSize: 10,
        },
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        optionListOne: [
          {
            id: 'inquiryNum',
            name: '询价量',
          },
          {
            id: 'dealNum',
            name: '已成交询价量',
          },
          {
            id: 'notDealNum',
            name: '未成交询价量',
          },
          {
            id: 'dealPercent',
            name: '询价成交率',
          },
          {
            id: 'weightSum',
            name: '总重量',
          },
          {
            id: 'volumeSum',
            name: '总体积',
          },
        ],
        optionListTwo: [
          {
            id: '1',
            name: '升序',
          },
          {
            id: '2',
            name: '降序',
          },
        ],
        beginTime: [],
        pickerOptions: {},
      }
    },
    mounted() {
      // this.beginTime.push(this.getDay(-30),this.getDay(-1))
      // this.form.beginDate=this.getDay(-30)
      // this.form.endDate=this.getDay(-1)
      this.beginTime.push(localFilter.getDay(-30), localFilter.getDay(-1))
      this.form.beginDate = localFilter.getDay(-30)
      this.form.endDate = localFilter.getDay(-1)
      this.queryList()
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    methods: {
      // getDay(day) {
      //   var today = new Date()
      //   var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
      //   today.setTime(targetday_milliseconds) //注意，这行是关键代码
      //   var tYear = today.getFullYear()
      //   var tMonth = today.getMonth()
      //   var tDate = today.getDate()
      //   tMonth = this.doHandleMonth(tMonth + 1)
      //   tDate = this.doHandleMonth(tDate)
      //   return tYear + '-' + tMonth + '-' + tDate
      // },
      // doHandleMonth(month) {
      //   var m = month
      //   if (month.toString().length == 1) {
      //     m = '0' + month
      //   }
      //   return m
      // },
      handleHeight() {
        this.height = this.$baseTableHeight(3) - 30
      },
      handleSizeChange(val) {
        this.form.pageNum = 1
        this.form.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.form.pageNum = val
        this.queryList()
      },
      pickerClick() {
        this.form.beginDate = this.beginTime ? this.beginTime[0] : ''
        this.form.endDate = this.beginTime ? this.beginTime[1] : ''
        this.queryList()
      },
      setSelectRows(val) {
        this.selectRows = val
      },
      handleBlur() {
        this.queryList()
      },
      async queryList() {
        this.listLoading = true
        const res = await productGetBulkCargoInquiryList(this.form)
        this.total = res.data.totalSize
        this.list = res.data.pageList
        this.listLoading = false
      },
    },
  }
</script>
