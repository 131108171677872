<template>
  <div>
    <el-form ref="form" :inline="true" :model="form" @submit.native.prevent>
      <el-form-item>
        时间
        <el-tooltip
          class="item"
          effect="dark"
          content="默认为前30天的数据，不包括今日"
          placement="top"
        >
          <i class="el-icon-warning"></i>
        </el-tooltip>
        <el-date-picker
          v-model="beginTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          unlink-panels
          @change="pickerClick"
          style="width: 350px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="产品专员">
        <el-select
          v-model="form.productCommissioner"
          multiple
          filterable
          collapse-tags
          @change="handleBlur"
          clearable
          placeholder="请选择"
          style="width: 180px"
        >
          <el-option
            v-for="item in optionList.productCommissionerGetValidList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="起运市">
        <el-select
          v-model="form.startCity"
          collapse-tags
          multiple
          filterable
          @change="handleBlurOne"
          clearable
          placeholder="请选择"
          style="width: 180px"
        >
          <el-option
            v-for="item in optionList.cityGetValidList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="起运区">
        <el-select
          v-model="form.startAreasIds"
          collapse-tags
          multiple
          filterable
          @change="handleBlur"
          clearable
          placeholder="请选择"
          style="width: 180px"
          :filter-method="areasFilterOne"
        >
          <el-option
            v-for="item in areasListOne"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="目的市">
        <el-select
          v-model="form.destCity"
          collapse-tags
          multiple
          filterable
          @change="handleBlurTwo"
          clearable
          placeholder="请选择"
          style="width: 180px"
        >
          <el-option
            v-for="item in optionList.cityGetValidList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="目的区">
        <el-select
          v-model="form.destAreasIds"
          collapse-tags
          multiple
          filterable
          @change="handleBlur"
          clearable
          placeholder="请选择"
          style="width: 180px"
          :filter-method="areasFilterTwo"
        >
          <el-option
            v-for="item in areasListTwo"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序列名">
        <el-select
          v-model="form.sortName"
          filterable
          style="width: 180px"
          @change="handleBlur"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in optionListOne"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="排序方式">
        <el-select
          v-model="form.sortRule"
          filterable
          @change="handleBlur"
          placeholder="请选择"
          style="width: 100px"
        >
          <el-option
            v-for="item in optionListTwo"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-button
        icon="el-icon-download"
        size="mini"
        type="primary"
        @click="handExport"
        :loading="anLoading"
      >
        导出
      </el-button>
    </el-form>
    <div style="height: calc(100vh - 300px)">
      <el-table
        ref="tableSort"
        v-loading="listLoading"
        border
        height="100%"
        :data="list"
        element-loading-text="拼命加载中"
        @selection-change="setSelectRows"
        header-cell-class-name="table-header"
        class="table eleChange"
      >
        <el-table-column
          align="left"
          prop="pickCityName"
          label="起运市"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          prop="pickAreasName"
          label="起运区"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          prop="dispatchCityName"
          label="目的市"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          prop="dispatchAreasName"
          label="目的区"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          prop="inquiryNum"
          label="询价量"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          prop="dealNum"
          label="已成交询价量"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          prop="notDealNum"
          label="未成交询价量"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          prop="dealPercent"
          label="询价成交率"
          show-overflow-tooltip
        />
        <el-table-column
          align="left"
          prop="volumeSum"
          label="总体积"
          show-overflow-tooltip
        >
          <template slot="header">
            总体积
            <el-tooltip effect="dark" content="CBM" placement="top">
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="weightSum"
          label="总重量"
          show-overflow-tooltip
        >
          <template slot="header">
            总重量
            <el-tooltip effect="dark" content="KG" placement="top">
              <i class="el-icon-warning"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </el-table>
    </div>
    <el-pagination
      background
      :current-page="form.pageNum"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
import {
  productAreasGetBulkCargoInquiryList,
  productAreasExportBulkCargoInquiryList,
} from '@/api/businessReport'
import { selectByCityId } from '@/api/inquiryManagement'
import * as localFilter from '@/utils/dataTime'
export default {
  props: {
    optionList: Object,
  },
  data() {
    return {
      anLoading: false,
      listLoading: false,
      height: this.$baseTableHeight(3) - 30,
      list: [],
      form: {
        sortName: 'inquiryNum',
        sortRule: '2',
        beginDate: '',
        endDate: '',
        productCommissioner: [],
        startCity: [],
        destCity: [],
        pageNum: 1,
        startAreasIds: [],
        destAreasIds: [],
        pageSize: 10,
      },
      total: 0,
      layout: 'total, sizes, prev, pager, next, jumper',
      optionListOne: [
        {
          id: 'inquiryNum',
          name: '询价量',
        },
        {
          id: 'dealNum',
          name: '已成交询价量',
        },
        {
          id: 'notDealNum',
          name: '未成交询价量',
        },
        {
          id: 'dealPercent',
          name: '询价成交率',
        },
        {
          id: 'weightSum',
          name: '总重量',
        },
        {
          id: 'volumeSum',
          name: '总体积',
        },
      ],
      optionListTwo: [
        {
          id: '1',
          name: '升序',
        },
        {
          id: '2',
          name: '降序',
        },
      ],
      beginTime: [],
      pickerOptions: {},
      areasListOne: [], //起运区
      areasListTwo: [], //目的区
      areasOne: [],
      areasTwo: [],
    }
  },
  mounted() {
    // this.beginTime.push(this.getDay(-30),this.getDay(-1))
    // this.form.beginDate=this.getDay(-30)
    // this.form.endDate=this.getDay(-1)
    this.beginTime.push(localFilter.getDay(-30), localFilter.getDay(-1))
    this.form.beginDate = localFilter.getDay(-30)
    this.form.endDate = localFilter.getDay(-1)
    this.queryList()
    this.selectList()
  },
  beforeMount() {
    window.addEventListener('resize', this.handleHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleHeight)
  },
  methods: {
    async selectList() {
      const red = await selectByCityId({})
      this.areasOne = red.data
      this.areasTwo = red.data
      this.areasFilterOne()
      this.areasFilterTwo()
    },
    areasFilterOne(query = '') {
      let arr = this.areasOne.filter((item) => {
        return item.name.includes(query) || item.name.includes(query)
      })
      if (arr.length > 200) {
        this.areasListOne = arr.slice(0, 200)
      } else {
        this.areasListOne = arr
      }
    },
    areasFilterTwo(query = '') {
      let arr = this.areasTwo.filter((item) => {
        return item.name.includes(query) || item.name.includes(query)
      })
      if (arr.length > 200) {
        this.areasListTwo = arr.slice(0, 200)
      } else {
        this.areasListTwo = arr
      }
    },
    // getDay(day) {
    //   var today = new Date()
    //   var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day
    //   today.setTime(targetday_milliseconds) //注意，这行是关键代码
    //   var tYear = today.getFullYear()
    //   var tMonth = today.getMonth()
    //   var tDate = today.getDate()
    //   tMonth = this.doHandleMonth(tMonth + 1)
    //   tDate = this.doHandleMonth(tDate)
    //   return tYear + '-' + tMonth + '-' + tDate
    // },
    // doHandleMonth(month) {
    //   var m = month
    //   if (month.toString().length == 1) {
    //     m = '0' + month
    //   }
    //   return m
    // },
    handleHeight() {
      this.height = this.$baseTableHeight(3) - 30
    },
    handleSizeChange(val) {
      this.form.pageNum = 1
      this.form.pageSize = val
      this.queryList()
    },
    handleCurrentChange(val) {
      this.form.pageNum = val
      this.queryList()
    },
    pickerClick() {
      this.form.beginDate = this.beginTime ? this.beginTime[0] : ''
      this.form.endDate = this.beginTime ? this.beginTime[1] : ''
      this.queryList()
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleBlur() {
      this.queryList()
    },
    async handleBlurOne() {
      const res = await selectByCityId({ cityIds: this.form.startCity })
      this.areasOne = res.data
      this.areasFilterOne()
      const set2 = new Set(this.areasOne.map((item) => item.id))
      let mixed = this.form.startAreasIds.filter((item) => set2.has(item))
      this.form.startAreasIds = mixed
      this.queryList()
    },
    async handleBlurTwo() {
      const res = await selectByCityId({ cityIds: this.form.destCity })
      this.areasTwo = res.data
      this.areasFilterTwo()
      const set2 = new Set(this.areasTwo.map((item) => item.id))
      let mixed = this.form.destAreasIds.filter((item) => set2.has(item))
      this.form.destAreasIds = mixed
      this.queryList()
    },
    async queryList() {
      this.listLoading = true
      const res = await productAreasGetBulkCargoInquiryList(this.form)
      this.total = res.data.totalSize
      this.list = res.data.pageList
      this.listLoading = false
    },
    async handExport() {
      this.anLoading = true
      const res = await productAreasExportBulkCargoInquiryList(this.form)
      const link = document.createElement('a')
      link.href = res.data.url
      link.click()
      this.anLoading = false
      this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
    },
  },
}
</script>